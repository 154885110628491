//login

.login-main {
  min-height: 100vh;
  display: flex;
  overflow: auto;

  > .container-fluid {
    > .row {
      height: 100%;
    }
  }
}

.login-wrap {
  height: 100%;
  padding: 1rem 0 1rem 0;
  max-width: 440px;
  margin: 0 auto;
}

.login-logo {
  width: 90px;
  margin-bottom: 10px;
}

.login-title {
  margin-bottom: 18px;
  p {
    margin-bottom: 0;
    color: $primary;
    font-size: 21px;
    line-height: 1;
  }

  h1 {
    font-size: 35px;
    color: $secondary;
    line-height: 1;
  }
}

.login-form {
  .form-group {
    label {
      color: $primary;
      font-size: 21px;
      margin-bottom: 0;
      height: 27px;
    }
  }
  .form-control {
    border-width: 0 0 1px 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-color: $primary;
    font-size: 21px;
    color: $secondary;

    @include placeholder($primary);
  }

  button {
    width: 170px;
    line-height: 1;
    height: 46px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .form-label-group {
    > label {
      top: -11px;
    }

    input:not(:placeholder-shown) ~ label {
      font-size: 21px !important;
      color: $primary !important;
      top: -12px;
    }

    input:-webkit-autofill {
      background-color: $body-bg !important;
      -webkit-text-fill-color: $secondary !important;
      -webkit-box-shadow: 0 0 0 30px $body-bg inset;
    }

    input:-webkit-autofill ~ label {
      font-size: 21px !important;
      color: $primary !important;
      top: -12px;
    }
  }

}

.login-signup-link {
  p {
    font-size: 18px;
    color: $secondary;
    a {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.login-right {
  position: absolute;
  background: url('/images/pawprints2.jpg') no-repeat;
  background-size: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 86.5%;
}

.login-right-lang {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  z-index: 1;
  padding: 10px 0 10px 0;
  max-width: 440px;
  margin: 0 auto;

  .login-laguage {
    //position: absolute;
    top: 70px;
    right: 30px;
    color: $primary;

    a {
      color: $primary;
      text-decoration: none;
    }
  }
  .login-right-kikland {
    //position: absolute;
    right: 30px;
    bottom: 60px;
    top: auto;
    //background-color: rgba($white, 0.55);
    width: 100px;
    height: 70px;
    text-align: center;
    img {
      width: auto;
      height: 45px;
      top: 50%;
      position: relative;
      transform: translateY(-50%);
      right: 0;
      left: 0;
      margin: 0 auto;
    }
  }
}

@include media-breakpoint-up(sm) {

  .login-main {
    padding-left: 50px;
  }

  .login-wrap {
    padding: 1.5rem 0 1.5rem 0;
  }

  .login-title {
    margin-bottom: 38px;

    h1 {
      font-size: 45px;
    }
  }

  .login-form {
    button {
      margin-top: 20px;
    }
  }
}

@include media-breakpoint-up(lg) {

  .login-main {
    padding-left: 50px;
  }

  .login-wrap {
    padding: 3rem 0 4rem 0;
  }

  .login-title {
    margin-bottom: 48px;

    h1 {
      font-size: 55px;
    }
  }

  .login-form {
    button {
      height: 56px;
      margin-top: 30px;
    }
  }

  .login-logo {
    width: 130px;
  }

  .login-right-lang {
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1;
    padding: 70px 30px 60px 0;
    max-width: none;

    .login-laguage {
      //position: absolute;
      top: 700px;
      right: 30px;
      color: $white;
      text-shadow: 1px 0 1px rgba($black, 0.6);

      a {
        color: $white;
        text-decoration: none;
        text-shadow: 1px 0 1px rgba($black, 0.6);
      }
    }
  }

}

@include media-breakpoint-up(xl) {

  .login-title {
    margin-bottom: 58px;

    h1 {
      font-size: 65px;
    }
  }

}

@include media-breakpoint-up(xxl) {

  .login-signup-link {
    p {
      font-size: 21px;
    }
  }

}
