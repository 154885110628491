/* Footer */

footer {
  //position: relative;
  background-color: $white;
  height: 62px;
  z-index: 1;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-msg {
  font-size: 14px;

  a {
    text-decoration: none;
    padding-bottom: 1px;
    color: $link-color;
    position: relative;

    &::before {
      content: '';
      display: block;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $link-color;
      transition: background-color .3s;
    }

    @include hover {
      &::after {
        background-color: $primary;
      }
    }
  }
}

.footer-nav {
  .btn {
    transition: opacity .3s, color .3s, background-color .3s;

    span {
      font-size: 10px;
    }
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: $white;
    opacity: .5;
    cursor: not-allowed;
  }
}


@media (max-width: 375px) {

  .footer-nav {
    .btn {
      @include hpad(8);
      font-size: 14px;
      span {
        font-size: 10px;
      }
    }
  }

}


@include media-breakpoint-up(md) {

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 93px;
  }

  .footer-msg {
    font-size: 18px;

    a {
      display: inline-block;

      &::before {
        display: none;
      }

      &::after {
        bottom: 3px;
      }
    }
  }

  .footer-nav {
    .btn {
      span {
        font-size: 12px;
      }
    }
  }

}


@include media-breakpoint-up(lg) {

  footer {
    height: 124px;
    margin-left: 33%;
  }

}


@include media-breakpoint-up(xl) {

  .footer-msg {
    font-size: 20px;
  }

}