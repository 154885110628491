// padding mixins
@mixin hpad($gutter) {
  padding-left: $gutter + px;
  padding-right: $gutter + px;
}

@mixin vpad($pad) {
  padding-top: $pad + px;
  padding-bottom: $pad + px;
}


// margin mixins
@mixin hmar($mar) {
  margin-left: $mar + px;
  margin-right: $mar + px;
}

@mixin vmar($mar) {
  margin-top: $mar + px;
  margin-bottom: $mar + px;
}

// Placeholder
@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
}


// Grid mixin
@mixin gutter($gw) {
  &.row {
    @include hmar(-$gw / 2);

    > [class^=col] {
      @include hpad($gw / 2);
    }
  }
}


// Image
@mixin cover-image() {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
