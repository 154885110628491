/* Select2 custom */

.select2-container--default{
.select2-results__option--highlighted[aria-selected] {
  background-color: $primary !important;
  position: relative;
  //color: rgba($primary, 0.4) !important;

  &::after {
   // background-color: darken($primary, 3%);
    color: $white;
    content: 'Press enter to select';
    display: inline-block;
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 37px;
    font-size: 14px;
  }
}


  .select2-results__option[aria-selected=true] {
    position: relative;
    background-color: rgba($primary, 0.1) !important;
    color: $primary;

    &:after{
      color: rgba($primary, 0.4);
      content: 'Selected';
      display: inline-block;
      padding: 0 10px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      line-height: 37px;
      font-size: 14px;
    }

    &:hover{
      background-color: $warning !important;
      position: relative;
      color: $white;

      &:after{
        color: $white;
        background-color: darken($warning, 3%);
        content: 'Click to remove';
        display: inline-block;
        padding: 0 10px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 37px;
        font-size: 14px;
      }
    }
  }

}

.select2 {
  &.select2-container {
    width: 100% !important;

    .select2-selection--multiple {
      min-height: 27px;
    }
  }


  &.select2-container--default {
    &.select2-container--focus {
      .select2-selection--multiple {
        border-color: #e7ecf8;
      }
    }

    .select2-selection--multiple {
      background-color: $white-f0;
      border: none;

      .select2-selection__choice {
        background-color: $white;
        border: none;
        margin-top: 6px;
        //font-size: 12px;
      }
    }

    .select2-search__field {
      width: 100%!important;
    }
  }
}

.order-replacement {
  .select2-container--default {
    .select2-selection--multiple {
      padding-right: 207px;

      .select2-selection__choice {
        padding: 4px 10px;
        border-radius: 16px;
      }
      .select2-selection__rendered {
        padding: 5px 12px 0;
      }
    }

    .select2-search--inline .select2-search__field {
      margin-top: 12px;
    }
  }
  .select2.select2-container .select2-selection--multiple {
    min-height: 57px;
    border-radius: 30px;
  }
}
