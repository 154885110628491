/* Vue Multiselect custom */

.multiselect {

  .multiselect__tags {
    min-height: 60px;
    top: 10px;
    padding-top: 15px;
  }

  .multiselect__tag {
    background-color: $primary;
    @include vpad(8);
  }

  .multiselect__tag-icon {
    line-height: 30px;
  }

  .multiselect__input,
  .multiselect__single {
    color: $gray-b5;
    margin-top: 5px;
  }

  .multiselect__select {
    top: 10px;
  }

  .multiselect__option {
    &.multiselect__option--highlight {
      background-color: $primary;

      &::after {
        background-color: darken($primary, 3%);
      }
    }
  }

  .multiselect__option--selected {
    background-color: rgba($primary, .1);

    &::after {
      color: rgba($primary, .4);
    }

    &.multiselect__option--highlight {
      background-color: lighten($warning, 10%);

      &::after {
        background-color: $warning;
      }
    }
  }

}
