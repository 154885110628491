//lost-pets-sidebar

.search-side-close{
  z-index: 12 !important;
}

.lost-sidebar{
  background: url(/images/dog.jpg) no-repeat center center/cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 1.2rem 1rem;
}

.lost-side-wrap{
  position: relative;
  z-index: 10;
}

.lost-overlay{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($primary, 0.95);
  z-index: 9;
}

.lost-side-top{

  h2{
    text-align: center;
  }


  .lost-paw {
    text-align: center;
    @include vpad(15);

    span.icon-pawprints{
      font-size: 35px;
      display: inline-block;
      line-height: 80px;
      background-color: $white;
      width: 80px;
      height: 80px;
      border-radius: 50%;

    }
  }
}

@include media-breakpoint-up(md){

  .lost-side-top{

    .lost-paw {
      @include vpad(50);

      span.icon-pawprints{
        font-size: 60px;
        line-height: 140px;
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }
    }
  }

  .lost-sidebar{
    padding: 9rem 1.2rem 4rem;
  }

}