//citylaws


.city-logo-img{
  a {
    width: 100px;
    height: 70px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.city-alert-wrap{
  padding: 10px;
}

.city-info-wrap {
  .v-line {
    border-bottom: 1px solid $primary;
    &:last-child{
      border-bottom: none;
    }
  }
}

.city-info-title {
  color: $secondary;
  text-align: center;
  font-size: 25px;
}

.contact-wrap {
  font-size: 14px;
  max-width: 330px;
  margin: 0 auto;
  min-height: auto;
}

.contact-title {
  min-width: 65px;
}

//new design css
.city-section-sec {
  background-color: $prime-light-bg;
}

.city-common-title {
  font-size: 1.5rem;
}

.back-citylaw {
  background-color: $white;
  position: sticky;
  bottom: 0;

  .btn {
    i {
      font-size: 14px;
      margin-right: 8px;
    }
  }
}

.setting-user-manage {
}

.user-manage-title {
  margin-bottom: 10px;
  h4 {
    font-size: 25px;
    i {
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}

@media (max-width: 480px) {
  .back-citylaw {
    .btn {
      padding: 7px 14px;
      font-size: 12px;
      i {
        font-size: 10px;
      }
    }
  }
}

@include media-breakpoint-up(md){
  .city-info-wrap {
    .v-line {
      border-right: 1px solid $primary;
      border-bottom: none;

      &:last-child{
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .contact-wrap {
    min-height: auto;
  }

  .city-common-title {
    font-size: 2rem;
  }

  .city-inner-box {
    //height: 150px;
    overflow: auto;
  }
}

@include media-breakpoint-up(xl) {
  .city-common-title {
    font-size: 2.5rem;
  }
}