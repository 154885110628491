//notices

.notice-title {
  font-size: 28px;
  line-height: 1;
}

.notice-table {
  border-spacing: 0 1em;
  border-radius: 5px;
  @include hpad(2);
  border-collapse: separate;

  .custom-head {
    border-collapse: collapse;
    th {
      border: none;
      color: $gray;
      vertical-align: middle;
      white-space: nowrap;
      font-weight: normal;
      text-align: center;
      @include vpad(3);
      font-size: 15px;
      //background-color: $gray-ef;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  tr.notice-list-head {
    border-collapse: separate;
    box-shadow: 0 0 3px rgba($black, 0.16);
    padding: 0 5px;
    border-radius: 10px;
    cursor: pointer;

    td {
      border-collapse: separate;
      font-size: 18px;
      text-align: center;
      border: none;
      vertical-align: middle;
      white-space: nowrap;
      &.spec-info{
        padding: 0 !important;
        height: auto;
        background-color: $white-f9;
        p{
          padding: 20px;
          text-indent: 20px;
          font-size: 14px;
          white-space: normal;
          text-align: left;
          margin-bottom: 0;
        }
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .unpaid {
        color: $warning;
      }

      .paid {
        color: $green;
      }

      .icon-tick {
        color: $green;
        font-size: 20px;
        margin-left: 10px;
      }

      .icon-cancel {
        font-size: 20px;
      }

      &.address {
        transition: color .2s;
        &:hover {
          color: $black !important;
        }
      }
    }
  }
  .tr-white {
    background-color: $white;
    transition: background-color 0.3s;
      &:hover {
        background-color: rgba($gray-e, 0.1);
      }

    /*&.h-effect {
      &:hover {
        background-color: rgba($gray-e, 0.1);
      }
    }*/
  }
}

//new css design
.filter-bar {
  border-top: 1px solid rgba($gray, 0.15);
  border-bottom: 1px solid rgba($gray, 0.15);
}

.licence-wrap {
  display: flex;
  align-items: center;
  padding: 3px 0;

  h3{
    font-weight: normal;
    padding-right: 15px;
    font-size: 20px;
  }

  .licence-detail {
    display: inline-block;
    word-break: break-all;
    white-space: normal;
    font-size: 16px;
    line-height: 1;
  }
}

@include media-breakpoint-up(md) {
  .notice-title {
    font-size: 32px;
  }

  .notice-table {
    .custom-head {
      th {
        font-size: 15px;
      }
    }

    tr.notice-list-head {
      td {
        font-size: 22px;
      }
    }
  }

}

@include media-breakpoint-up(xl) {
  .notice-title {
    font-size: 38px;
  }

  .notice-table {

    tr.notice-list-head {
      td {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .licence-wrap {

    h3{
      font-size: 24px;
    }

    .licence-detail {
      font-size: 20px;
    }
  }
}
