@font-face {
  font-family: 'icomoon';
  src:  url('#{$font-path}/icomoon.eot?#{$font-slug}');
  src:  url('#{$font-path}/icomoon.eot?#{$font-slug}#iefix') format('embedded-opentype'),
    url('#{$font-path}/icomoon.ttf?#{$font-slug}') format('truetype'),
    url('#{$font-path}/icomoon.woff?#{$font-slug}') format('woff'),
    url('#{$font-path}/icomoon.svg?#{$font-slug}#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus-circle:before {
  content: "\e908";
}
.icon-down:before {
  content: "\e907";
}
.icon-alarm-bell:before {
  content: "\e904";
  color: #7c7c7c;
}
.icon-bar:before {
  content: "\e905";
  color: #d6d6d6;
}
.icon-bell-notification:before {
  content: "\e906";
}
.icon-bin:before {
  content: "\e909";
  color: #7c7c7c;
}
.icon-building:before {
  content: "\e90a";
  color: #fff;
}
.icon-cancel:before {
  content: "\e90b";
}
.icon-warn-sign:before {
  content: "\e90c";
  color: #fff;
}
.icon-dash-icon:before {
  content: "\e90d";
  color: #fff;
}
.icon-edit-pen:before {
  content: "\e90e";
  color: #7c7c7c;
}
.icon-lock:before {
  content: "\e90f";
  color: #7c7c7c;
}
.icon-lost-pet:before {
  content: "\e910";
  color: #fff;
}
.icon-paw-small:before {
  content: "\e920";
  color: #fff;
}
.icon-plus-addition-sign-circle:before {
  content: "\e921";
  color: #fff;
}
.icon-small-flag:before {
  content: "\e922";
  color: #fff;
}
.icon-support:before {
  content: "\e923";
  color: #fff;
}
.icon-tick:before {
  content: "\e924";
}
.icon-addplus:before {
  content: "\e902";
  color: #859fda;
}
.icon-arrow-left:before {
  content: "\e900";
  color: #fff;
}
.icon-arrow-right:before {
  content: "\e901";
  color: #fff;
}
.icon-flag:before {
  content: "\e903";
  color: #859fda;
}
.icon-pawprints:before {
  content: "\e911";
  color: #859fda;
}
.icon-plus:before {
  content: "\e912";
  color: #7c7c7c;
}
