//support

.faq-card {
  box-shadow: 0 0 3px rgba($black, 0.16);
  border: none;
}

.faq-header {
  a {
    display: block;
    color: $secondary;
    padding: 20px 20px;
    text-decoration: none;
    position: relative;

    h5 {
      font-size: 18px;
      color: $secondary;
      padding-right: 28px;
      padding-left: 20px;
      span {
        color: $primary;
        position: absolute;
        left: 15px;
      }
    }

    &.collapsed {
      position: relative;
      &:after {
        content: "\E907";
        font-family: 'icomoon';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 11px;
      }
    }

    &:after {
      content: "\E907";
      font-family: 'icomoon';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(-180deg);
      transition: transform 0.3s;
      font-size: 11px;
    }
  }
}

.faq-body {
  background-color: $white-f9;
  text-indent: 30px;
}

.milo-support-wrap {
  .v-line {
    border-bottom: 1px solid $gray-e;
    @include hmar(15);
    &:last-child {
      border-bottom: none;
    }
  }
}

.support-telephone {
  max-width: 200px;
  margin: 0 auto;
}

.support-form {
  .form-control {
    background-color: $white-f0;
  }

  .form-control {
    &.support-mail {
      height: 25px;
    }
  }

  .form-group {
    margin-bottom: 5px;
    label {
      font-size: 13px;
      font-weight: normal;
      margin-bottom: 5px;
    }
    button {
      border-radius: 0 0 10px 10px;
      width: 100%;
      padding: 2px 30px;
      font-size: 15px;
    }
  }
}

.live-chat {
  max-width: 130px;
  margin: 0 auto;

  h6 {
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 5px;

    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      box-shadow: 0 0 4px #59E64D;
      margin-left: 5px;

      &.online {
        background-color: $green;
      }
      &.away {
        background-color: $orange;
      }
      &.offline {
        background-color: $red;
      }
    }
  }

  button {
    margin-left: -15px;
  }
}

.order-replacement {
  position: relative;
  margin-top: 20px;

  .form-control {
    height: 44px;
    border-radius: 30px;
    background-color: $white-f0;
  }

  button {
    margin-top: 10px;
  }
}

@include media-breakpoint-up(sm) {
  .order-replacement {

    .form-control {
      height: 44px;
      padding-right: 175px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0;
    }
  }
}



@include media-breakpoint-up(md) {
  .milo-support-wrap {
    .v-line {
      @include hmar(0);
      border-right: 1px solid $gray-e;
      border-bottom: 0;

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .order-replacement {
    .form-control {
      height: 56px;
      padding-right: 215px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .faq-header {
    a {
      padding: 31px 20px;
      h5 {
        font-size: 29px;
        padding-left: 28px;
      }
    }
  }

  .milo-support-wrap {
    .v-line {
      border-right: 1px solid $gray-e;
      border-bottom: none;
      @include hmar(0);

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }
}
