/* Bootstrap Custom */

.form-control {
  box-shadow: 0 0 2px rgba($black,0.16);
}

.less-pad.row {
  margin-right: -8px;
  margin-left: -8px;
  > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.custom-time-select {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $white;
    border: none;

    &.open {
      color: $green;
    }

    &.closed {
      color: $red;
    }
  }

  .icon-custom {
    position: absolute;
    width: 2em;
    height: 100%;
    top: 0;
    right: 0;
    display: inline-block;
    pointer-events: none;

    &:after {
      font-family: 'icomoon' !important;
      font-size: 8px;
      content: "\E907";
      border-radius: 50%;
      color: $body-text;
      display: inline-block;
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  }
}

.custom-input-group {
  .input-group-text {
    background: $linearGradient;
    color: $white;
  }
}

.custom-upload {

  .upload-input {
    position: relative;
    height: 25px;
    max-width: 76px;
    overflow: hidden;
    cursor: pointer;
    label {
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 5px;
      color: $white;
      background: $linearGradient;
      cursor: pointer;
      width: 76px;
      text-align: center;
      line-height: 1;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}


.navbar-brand {
  width: 100px;
}

.btn {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 25px;

  &:focus{
    box-shadow: none;
  }

  span {
    margin-left: 5px;
  }

  span.back {
    margin-right: 5px;
  }
}

.btn-primary {
  color: $white;
  background-image: linear-gradient(-10deg,#9FBAF6,#6F98F1);
}

.bg-primary-grad {
  background-image: linear-gradient(-10deg,#9FBAF6,#6F98F1);
}

.btn-default {
  color: $white;
  background-image: linear-gradient(-10deg,#E6E6E6,#A7A7A7);
  &:hover {
    color: $white;
  }
}

.btn-gray {
  color: $gray;
  background-image: linear-gradient(-190deg,#FFF,#EAEAEA);
}

.btn-table-view{
  color: $white;
  background-image: linear-gradient(-10deg,#A7A7A7,$gray-e6);
}

.custom-checkbox {

  .custom-control-label {
    &:before {
      background-color: $white;
      box-shadow: 0 0 2px rgba($black,0.16);
      width: 22px;
      height: 22px;
    }

    &:after {
      width: 22px;
      height: 22px;
    }
  }
}


.filter-option {

  .custom-control-label {
    background-color: transparent;
    padding: 5px 35px 5px 10px;
    border-radius: 5px;
    text-align: left;
    min-width: auto;
    font-size: 16px;
    font-weight: bold;
    color: $primary;
    &:before {
      background-color: transparent;
      width: 0;
      height: 0;
      top: 50%;
      right: 7px;
      left: auto;
      transform: translateY(-50%);
      box-shadow: none !important;
    }
    &:after {
        top: 50%;
        right: 7px;
        left: auto;
        transform: translateY(-50%);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    background-color: $primary !important;
    color: $white;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary;
    width: 22px;
    height: 22px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: $white;
    background-color: $primary;
  }
}

.custom-radio {
  padding-left: 0;
  padding-right: 1.5rem;

   .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
    background-color: $gray;
     width: 10px;
     height: 10px;
     margin-right: 6px;
     margin-top: 6px;
     border-radius: 10px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $white;
  }

  .custom-control-label {
    line-height: 32px;
    color: $gray;
    &:before{
      background-color: $white;
      box-shadow: 0 0 2px rgba($black,0.16);
      width: 22px;
      height: 22px;
      left: auto;
      right: -2rem;
    }

    &:after{
      width: 22px;
      height: 22px;
      left: auto;
      right: -2rem;
    }
  }
}

.custom-pill {
  background-color: $white;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba($black, 0.16);

  .nav-link {
    &.active{
      border-radius: 0;
      background-image: linear-gradient(-10deg,#9FBAF6,#6F98F1);
    }
  }
    li{
      width: 50%;
      text-align: center;
      background-color: $white;

      a {
        color: $gray;
        font-weight: 500;
      }
    }
}

.modal-backdrop{
  background-color: transparent;
}

.modal-open{
  .common-wrap{
    filter:blur(25px);
  }
}

.filter-dropdown {
  margin: 0 2rem 0 0;
  //position: relative;

  &:last-child {
    margin-right: 0;
  }

  button {
    color: $body-text;
    i {
      font-size: 8px;
    }
  }
}

.filter-drop-menu {
  box-shadow: 0 0 3px rgba($black, 0.16);
  border: none !important;
  min-width: auto;
  max-width: 200px;
  margin: 0 auto;
  padding: 0 1.5rem;

  &.dropdown-menu {
    //top: 100% !important;
    //left: 50% !important;
    //right: auto;
    //text-align: left;
    //transform: translateX(-50%) !important;

    /*&:before {
      right: auto;
      display: inline-block;
      content: '';
    }*/
    top: 50px !important;
    left: -13px !important;
    transform: none !important;
    right: -13px !important;
    max-width: none !important;
    background-color: $white-f0;
  }

  /*&:before {
    content: ' ';
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    background: url("/images/menutop.svg") no-repeat center;
    width: 27px;
    height: 27px;
  }*/


  /*&.dropdown-menu[x-placement^="top"] {
    &::before {
      content: ' ';
      position: absolute;
      top: auto;
      bottom: -22px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
      background: url("/images/menutop.svg") no-repeat center;
      width: 27px;
      height: 27px;
    }
  }

  &.dropdown-menu[x-placement^="bottom"] {
    &::before {
      content: ' ';
      position: absolute;
      top: -22px;
      left: 50%;
      transform: translateX(-50%);
      background: url("/images/menutop.svg") no-repeat center;
      width: 27px;
      height: 27px;
    }
  }*/

  a {
    //border-bottom: 1px solid rgba($gray, 0.25);
    white-space: normal;
    max-width: none;
    text-align: center;
    display: inline-block;
    width: auto;
    padding: 0.25rem 0.25rem;

    &:hover, &:focus {
      background-color: transparent;
    }

    &:last-child {
      border-bottom: none;
    }
  }

}

.clear-all {
  padding-left: 10px;
  button {
    background-color: $gray-b5;
    border-radius: 5px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    padding: 9px;
    line-height: 1;
    i {
      font-size: 14px;
      padding-left: 5px;
    }
  }
}

@include media-breakpoint-up(md) {

  .btn {
    font-size: 18px;
    padding: 15px 30px;
    border-radius: 30px;
  }

  .filter-dropdown {
    margin: 0 1.5rem 0 1rem;
  }
}

@include media-breakpoint-up(xl) {
  .filter-drop-menu {
    &.dropdown-menu {
      left: -35px !important;
      right: -35px !important;
      max-width: none !important;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .filter-dropdown {
    margin: 0 3rem 0 2rem;

    &:first-child {
      margin-left: 0;
    }
  }

}