/* Register */

.register-wrap {
  position: relative;
}

.register-wrap {
  &.step-1 {
    background: url("/images/dog.jpg") no-repeat center center/cover;
  }
  &.step-2 {
    background: url("/images/dog.jpg") no-repeat center center/cover;
    height: 85px;
  }
}

.register-slider {
  height: 240px;
  position: relative;
  z-index: 10;

  .carousel-item {
    text-align: center;
    @include hpad(15);
  }

  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba($white,.5);

      &.active {
        width: 14px;
        height: 14px;
        background-color: $white;
        position: relative;
        top: -2px;
      }
    }
  }
}

.register-slider-caption {
  padding-top: 30px;
  padding-bottom: 15px;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: $white;
  }
}

.register-slider-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 25px 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  span {
    font-size: 50px;
  }
}

.register-form-wrap {
  max-width: 91%;
  margin: 0 auto;
  width: 100%;

  h1 {
    color: $secondary;
    font-weight: 600;
  }
}

.privacy-policy {
  line-height: 1.7;
  .custom-control-label {
    font-size: 16px;
    padding-left: 10px;
    a {
      color: $gray;
      text-decoration: underline;
    }

  }
}

.register-form {

  .form-control {
    height: 40px;
  }

  select {
    &.form-control {
      height: 40px !important;
    }
  }
}

.register-img {
  height: 300px;
  @include cover-image;
  background-image: url("/images/dog.jpg");
}

.register-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($primary, .95);
  z-index: 9;
}

.register-steps {
  position: relative;
  z-index: 10;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.register-step {
  display: flex;
  flex-direction: column;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  text-align: center;
  position: relative;
}

.register-step::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 50%;
  top: 8px;
  height: 4px;
  background-color: $register-bg;
  z-index: 0;
}

.register-step:last-child::after {
  display: none;
}

.register-step.active .step-dot {
  background-color: $primary;
  width: 30px;
  height: 30px;
  border: 9px solid $white;
  margin-top: -4px;
}

.step-dot {
  width: 20px;
  height: 20px;
  background-color: $register-bg;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.step-label {
  font-size: 14px;
  font-weight: bold;
  color: $register-bg;
  margin-top: 4px;
}

.active .step-label {
  color: $white;
  text-align: left;
}

.register-add {
  a.icon-plus {
    width: 90px;
    height: 90px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    line-height: 90px;
    font-size: 40px;
    text-decoration: none;
    box-shadow: 0 0 2px rgba($black, 0.16);
  }
  a{
    text-decoration: none;
  }
}

.dob {
  position: relative;
  z-index: 1;
}

.btn-dob {
  position: absolute;
  right: 3px;
  top: 1px;
  z-index: 5;
  border-radius: 0;
  padding: 15px 10px;

  img {
    width: 12px;
    height: auto;
  }

  @include hover-focus-active {
    outline: none;
    box-shadow: none;
  }
}


.popover {max-width:400px;}
#popover-content-logout > * {
  background-color:#ff0000 !important;
}

.table {
  .thead-primary {
    th{
    color: $white;
    background-color: $gray-b5;
    border-color: $gray-b5;
    }
  }
}

.logo-right {
  width: 100px;
  height: 70px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}


@include media-breakpoint-up(md) {

  .button-switch {
    label {
      width: 150px;
    }
  }

}

@include media-breakpoint-up(lg) {

  .register-slider {
    height: 100vh;

    .carousel-item {
      padding-top: 20vh;
      @include hpad(25);
    }
  }

  .register-slider-caption {
    h2 {
      font-size: 26px;
      margin-bottom: 50px;
    }
  }

  .register-slider-icon {
    width: 200px;
    height: 200px;
    @include vpad(50);

    span {
      font-size: 100px;
    }
  }

  .register-wrap.step-2 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .register-img {
    height: 100vh;
  }

  .register-form {

    .form-control {
      height: 60px;
    }

    select {
      &.form-control {
        height: 60px !important;
      }
    }
  }

  .register-form-wrap {
    min-height: 100%;
    max-width: 80%;
    margin: 0 auto;
  }

  .privacy-policy {
    .custom-control-label {
      font-size: 18px;
    }
  }

  .register-steps {
    flex-direction: column;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .register-step {
    flex-direction: row;
    min-height: 150px;
    flex: 1 1 auto;
    max-width: 100%;
    align-items: center;
  }

  .register-step::after {
    width: 4px;
    height: 100%;
    top: 50%;
    left: 20px;
    margin-left: -2px;
  }

  .register-step.active .step-dot {
    margin-top: 0;
    margin-left: -10px;
    width: 60px;
    height: 60px;
    border-width: 18px;
  }

  .step-dot {
    width: 40px;
    height: 40px;
    margin-left: 0;
    margin-right: 15px;
  }

  .step-label {
    font-size: 24px;
  }

}

@include media-breakpoint-up(xl) {

  .register-slider-icon {
    width: 300px;
    height: 300px;
    @include vpad(70);

    span {
      font-size: 160px;
    }
  }

  .register-slider-caption {
    h2 {
      font-size: 35px;
    }
  }

  .register-slider {
    .carousel-indicators {
      padding-bottom: 40px;

      li {
        width: 14px;
        height: 14px;
        @include hmar(6);

        &.active {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .register-form-wrap {
    max-width: 70%;
    margin: 0 auto;
  }

  .register-steps {
    width: 270px;
  }

  .step-label {
    font-size: 30px;
  }

}



@include media-breakpoint-up(xxl) {

  .register-form-wrap {
    max-width: 60%;
  }

}