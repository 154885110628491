//dashboard
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
  width: 310px;
  position: fixed;
  top: 0;
  left: -310px;
  height: 100vh;
  z-index: 999;
  /*background: $primary;*/
  color: $white;
  transition: all 0.3s;
  overflow-y: scroll;
  &.active {
    left: 0;
  }
}

#dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #7386D5;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s;
  &:hover {
    background: $white;
    color: #7386D5;
  }
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  &.active {
    display: block;
    opacity: 1;
  }
}

.sidebar-logo2 {
  //background-color: rgba($white, 0.25);
  width: 100px;
  height: 70px;
  line-height: 1;
  margin: 15px auto 0 auto;
}

#sidebar {
  .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
  }
  ul {
    &.components {
      padding: 0;
    }
    li {
      a {

        padding: 10px 10px 10px 65px;
        min-height: 124px;
        line-height: 103px;
        font-size: 25px;
        display: block;
        text-decoration: none;
        color: $white;
        border-bottom: 1px solid rgba($white, 0.25);
        position: relative;
        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 30px;
        }
      }
    }
  }
}

.admin-sidebar {
    background: linear-gradient(0deg, rgba(#2C4A88, 1) 0%, rgba(#5F8CF0, 1) 100%);

  ul {
    li {
      a {
        &:hover {
          background: $secondary;
        }
      }
      &.active > a {
        background-color: $secondary;
        border-left: 5px solid $primary;
      }
    }
  }
}

.user-sidebar {
  background: linear-gradient(0deg, rgba(95,140,240,1) 0%, rgba(180,201,248,1) 100%);

  ul {
    li {
      a {
        &:hover {
          background: rgba($primary, 0.5);
        }
      }
      &.active > a {
        background-color: rgba($primary, 0.45);
        border-left: 5px solid $secondary;
      }
    }
  }
}

/*a {
  &[aria-expanded="true"] {
    color: $white;
    background: #6d7fcc;
  }
  &[data-toggle="collapse"] {
    position: relative;
  }
}*/

.profile-dropdown {
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 10px;
    right: -5px;
    border: none;
    transform: translateY(-50%);
  }
}

/*ul {
  ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
  }
  &.CTAs {
    padding: 20px;
    a {
      text-align: center;
      font-size: 0.9em !important;
      display: block;
      border-radius: 5px;
      margin-bottom: 5px;
    }
  }
}*/

/*a {
  &.download {
    background: $white;
    color: #7386D5;
  }
  &.article {
    background: #6d7fcc !important;
    color: $white !important;
    &:hover {
      background: #6d7fcc !important;
      color: $white !important;
    }
  }
}*/

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.icon-warn-sign {
  font-size: 20px;
}

.total-fine {
  font-size: 20px;
}

.fine-list {
  font-size: 20px;
}

.total-bal {
  span {
    font-size: 20px;
  }

  button {
    font-weight: bold;
  }
}

.city-box {
  min-height: 300px;
  p {
    padding: 34px 50px 40px;
    font-size: 14px;
  }

  ul {
    max-width: 368px;
    margin: 0 auto;
    font-size: 14px;
    li {
      padding-bottom: 10px;
    }
  }
}

.dog-info {
  .animal-name {
    font-size: 34px;
    text-decoration: none;
  }
  .animal-type {
    font-size: 16px;
  }
  .valid-till {
    font-size: 17px;
    i {
      &.icon-tick {
        color: $green;
      }

      &.icon-cancel {
        color: $warning;
      }
    }
  }
}

.dog-img {
  width: 93px;
  height: 93px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  img {
    position: absolute;
    min-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.view-all {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;

  a {
    text-decoration: underline;
  }
}

//new design css
.dash-revenue-box {
  background-color: $white;
  box-shadow: 0 0 3px rgba($black, 0.08);
  border-radius: 10px;
  min-height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 38px;
    color: $secondary;
  }

  p {
    margin-bottom: 0;
    color: $primary;
    font-size: 16px;
  }
}

.dash-registered-pets {
  background-image: linear-gradient(64deg, #9FBAF6, #6F98F1);
  color: $white;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba($black, 0.08);
  display: flex;
  min-height: 180px;

  h1 {
    font-size: 72px;
  }
  p {
    font-size: 20px;
    margin-bottom: 0;
    line-height: 1;
    text-align: center;
  }
  h2 {
    font-size: 60px;
    line-height: 1;
  }
}

.dash-dog-reg {
  background-color: $white;
  box-shadow: 0 0 3px rgba($black, 0.08);
  border-radius: 10px;
  min-height: 93px;

  img {
    width: 30px;
    height: 30px;
  }
  p {
    font-size: 20px;
    color: $secondary;
    line-height: 1;
    span {
      font-size: 25px;
      display: inline-flex;
      vertical-align: middle;
    }
  }
}

.dash-license-exp {
  background-color: $warning;
  box-shadow: 0 0 3px rgba($black, 0.08);
  border-radius: 10px;
  min-height: 93px;
  padding: 0 5px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  p {
    font-size: 29px;
    line-height: 1;
    text-decoration: none;
  }
  svg {
    font-size: 20px;
    color: $white;
  }

  &.active {
    background-color: $green;
  }
}

.dash-new-post {
  font-size: 18px;
  color: $gray;
  text-decoration: underline;
}

.recent-notices-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba($black, 0.08);
  max-height: 296px;
  margin-bottom: 16px;

  .recent-notices {
    position: relative;
    transition: filter 0.3s;
    background-color: $white;
    .temp {
      width: 100%;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      height: 270px;
      img {
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .notice-hover {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    transition: top 0.3s;
    background-color: rgba($gray-d5, 0.9);
    overflow: hidden;

    a {
      display: block;
      position: absolute;
      font-size: 57px;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
      text-decoration: none;
      width: 100%;
      text-align: center;
    }
  }

  .mobile-edit {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba($primary, 0.85);
    border-radius: 0 0 0 50%;
    padding: 7px;
    width: 40px;
    height: 40px;
    text-align: center;
    text-decoration: none;
    img {
      width: 18px;
    }
  }
}

.dash-notice-img {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 180px;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.dash-notice-title {
  font-size: 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1;
  }
}

.dash-revenue-chart {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba($black, 0.08);

  h5 {
    text-align: center;
    background: $linearGradient;
    padding: 5px;
    color: $white;
    font-size: 15px;
  }

  .highcharts-title {
    display: none;
  }

  .highcharts-point .highcharts-color-3 {
    fill: url(#grad1);
  }
  .highcharts-legend-item {
    display: none;
  }
}
.charts-chart {
  .highcharts-yaxis, .highcharts-xaxis {
    .highcharts-axis-title {
      display: none;
    }
  }
}

@include media-breakpoint-up(md) {

  .icon-warn-sign {
    font-size: 22px;
  }

  .total-fine {
    font-size: 22px;
  }

  .fine-list {
    font-size: 22px;
  }

  .total-bal {
    span {
      font-size: 22px;
    }

    button {
      font-weight: bold;
    }
  }
}

@include media-breakpoint-up(lg) {
  .profile-dropdown {
    .dropdown-toggle::after {
      display: inline-block;
      position: relative;
      font-family: 'icomoon' !important;
      font-size: 7px;
      content: "\E907";
      border-radius: 50%;
      background: rgba($body-text, 0.15);
      color: $body-text;
      padding: 0;
      width: 18px;
      height: 18px;
      line-height: 20px;
      text-align: center;
      font-weight: bold;
    }
  }

  //new designcss

  .recent-notices-wrap {

    .mobile-edit {
      top: -100%;
      right: -100%;
      transition: top 0.3s, right 0.3s;
    }

    &:hover {
      .recent-notices {
       // filter: blur(3px);
      }

      .notice-hover {
        top: 0;
      }

      .mobile-edit {
        top: 0;
        right: 0;
      }
    }
  }

}

@include media-breakpoint-up(xl) {

  #content {
    width: calc(100% - 250px);
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;

    &.active {
      width: 100%;
      transition: all 0.3s;
    }
  }

  .sidebar-logo2 {
    width: 100px;
    height: 70px;
    line-height: 1;
    margin: 25px auto 8px auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  #sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    color: $white;
	background-color: rgba(133, 159, 218, 0.95);
    transition: all 0.3s;
    overflow-y: scroll;
  }

  .icon-warn-sign {
    font-size: 28px;
  }

  .total-fine {
    font-size: 28px;
  }

  .fine-list {
    font-size: 28px;
  }

  .total-bal {
    span {
      font-size: 28px;
    }

    button {
      font-weight: bold;
    }
  }

  .sidebar-logo2 {
    /*width: 115px;
    height: 115px;
    line-height: 115px;
    img {
      width: 80px;
      height: 80px;
    }*/
  }

  #sidebar {
    .sidebar-header {
      padding: 20px;
    }
    ul {

      li {
        a {
          padding: 10px 10px 10px 65px;
          min-height: 90px;
          line-height: 1;
          display: flex;
          align-items: center;
          font-size: 25px;
        }
      }
    }
  }

  //new design css

  .dash-registered-pets {
    min-height: 220px;
    h1 {
      font-size: 92px;
    }
    p {
      font-size: 22px;
    }
    h2 {
      font-size: 65px;
    }
  }

  .recent-notices {
    .temp {
      height: 296px;
    }
  }

  .dash-notice-img {
    height: 200px;
  }

  .dash-notice-title {
    h4 {
      font-size: 25px;
    }
  }

}

@include media-breakpoint-up(xxl) {

  #content {
    width: calc(100% - 310px);
  }

  #sidebar {
    width: 310px;

    .sidebar-header {
      padding: 20px;
    }
    ul {
      li {
        a {
          padding: 10px 10px 10px 65px;
          min-height: 124px;
          line-height: 103px;
          font-size: 25px;
        }
      }
    }
  }

  //new design css

  .dash-registered-pets {
    h1 {
      font-size: 102px;
    }
    p {
      font-size: 27px;
    }
    h2 {
      font-size: 80px;
    }
  }
}
