//modal-custom

.custom-modal {

  top: 0;
  padding-top: 70px;

  .close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 22px;
    z-index: 1;
    padding: 0 !important;
  }
}

.custom-modal-centered {
  max-width: 500px;
  margin: 0 auto;
  @include hpad(10);
}

.custom-modal-content {
  border: none;
  box-shadow: 0 0 6px rgba($black, 0.08);
  margin: 0.5rem 0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.modal-form-body {
  overflow-y: auto;
  padding: 1rem;

  .register-add {
    a {
      background-color: $white-f0;
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 30px;
    }
  }
}

.modal-form {
  .form-control {
    height: 37px;
    background-color: $white-f0;
    padding: 0.2rem 0.5rem;

    &::placeholder {
      color: $gray;
    }
  }

  textarea {
    &.form-control {
      height: auto;
    }
  }

  select.form-control {
    height: 37px !important;
    background-color: $white-f0;
    padding: 0.2rem 0.5rem;

    &::placeholder {
      color: $gray;
    }
  }

  .custom-checkbox {

    .custom-control-label {
      &:before {
        background-color: $white;
        box-shadow: 0 0 2px rgba($black, 0.16);
        width: 22px;
        height: 22px;
      }

      &:after {
        width: 22px;
        height: 22px;
      }
    }
  }

  .custom-radio {
    padding-left: 0;
    padding-right: 1.5rem;

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: none;
      background-color: $gray;
      width: 10px;
      height: 10px;
      margin-right: 6px;
      margin-top: 6px;
      border-radius: 10px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $gray-e6;
    }

    .custom-control-label {
      line-height: 32px;
      color: $gray;

      &:before {
        background-color: $gray-e6;
        box-shadow: 0 0 2px rgba($black, 0.16);
        width: 22px;
        height: 22px;
        left: auto;
        right: -2rem;
      }

      &:after {
        width: 22px;
        height: 22px;
        left: auto;
        right: -2rem;
      }
    }
  }

  /* .custom-pill {
     background-color: $white;
     overflow: hidden;
     border-radius: 5px;
     box-shadow: 0 0 2px rgba($black, 0.16);

     .nav-link {
       padding: 0 1rem;
       height: 27px;
       line-height: 27px;

       &.active{
         border-radius: 0;
         background-image: linear-gradient(-10deg,#9FBAF6,#6F98F1);
       }
     }
     li{
       width: 50%;
       text-align: center;
       background-color: $white;

       a {
         color: $gray;
         font-weight: 500;
       }
     }
   }*/

  //pill
  .switch-field {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba($black, 0.16);
  }

  .switch-title {
    margin-bottom: 6px;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    float: left;
  }

  .switch-field label {
    margin-bottom: 0;
    display: inline-block;
    width: 50%;
    background-color: $white;
    color: $gray;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    //padding: 6px 14px;
    padding: 0 1rem;
    height: 27px;
    line-height: 27px;
    border-radius: 5px;
  }

  .switch-field label:hover {
    cursor: pointer;
  }

  .switch-field input:checked + label {
    color: $white;
    background-image: linear-gradient(-10deg, #9FBAF6, #6F98F1);
    box-shadow: none;
  }

  .switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  .switch-field label:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  //end pill

}

.modal-add-pet {
  button.btn {
    width: 100%;
    border-radius: 0 0 3px 3px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}

//dashboard view details modal
.view-details-box {
  .custom-btn-box {
    .btn {
      padding: 12px;
      font-size: 16px;
    }
  }
}

.info-gray-part {
  background-color: $white-f9;
  @include vpad(10);

  .gray-info-wrap {
    max-width: 275px;
    margin: 0 auto;

    .view-left-side {
      .details {
        span {
          @include text-truncate;
          max-width: 160px !important;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .view-right-side {
      .details {
        span {
          @include text-truncate;
          max-width:  160px !important;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

  }
}

.new-post-img {
  position: relative;
  text-align: center;
  height: 60px;
  border: 1px dashed #ccc;
  line-height: 60px;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  label {
    margin-bottom: 0;
    font-size: 18px;
  }
}

.modal {
  &.add-pet-modal-wrap {
    top: 80px !important;
    left: 0;
    background-color: $body-bg;
    padding-top: 0 !important;
    padding-right: 0 !important;
  }
}

.add-pet-modal-wrap {
  .custom-modal-content {
    background-color: $body-bg;
  }

  &.custom-modal{
    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 26px;
      padding: 10px !important;
    }
  }
}

.choose-pet-wrap {
  figure {
    background: $linearGradient;
    width: 120px;
    height: 120px;
    border-radius: 8px;

    img {
      width: 50px;
      height: 50px;
    }
  }
}

.add-pet-modal-wrap {
  padding-right: 0 !important;

  &:before {
    height: calc(100vh - 80px);
  }

  .custom-modal-content {
    align-items: center;
    margin: 0;
  }

  .modal-content {
    background-color: $body-bg;
    box-shadow: none;
  }

  .modal-form-body {
    padding: 0;
  }
}

.choose-pet-wrap {
  a {
    text-decoration: none;
  }

  figure {
    figcaption {
      color: $white;
      font-size: 18px;
      font-weight: 600;
      padding-top: 10px;
    }
  }
}

.base {
  position: fixed;
  bottom: 0;
  background-color: $white;
  width: 100%;
  left: auto;
  right: 0;
  text-align: right;
  padding: 10px;
}

//pet add modal step
/*form styles*/
#msform {
  min-width: 300px;
  margin: 0 auto;
  //text-align: center;
  position: relative;
  height: calc(100vh - 80px);
}

#msform fieldset {
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 100%;

  .field-wrap {
    height: 100%;
    justify-content: center;
    align-items: center;

    .modal-form {
      min-width: 100%;
      padding: 0 10px 64px 10px;
      overflow-y: auto;

      .switch-field {
        width: 100%;
        max-width: 200px;
      }

      .form-control {
        background-color: $white;
      }

      .form-group {
        .file-upload-form {
          position: relative;

          label {
            font-size: 12px;
            margin-right: 8px;
          }

          .choose-file {
            input {
              width: 81px;
              background-color: transparent;
              box-shadow: none;
              font-size: 12px;
              opacity: 0;
              position: relative;
              z-index: 1;
            }

            label {
              position: absolute;
              top: 0;
              background-color: $white;
              margin: 0;
              right: 0;
              padding: 4px 4px;
              border-radius: 5px;
              text-align: center;
              width: 74px;
            }
          }


        }
      }
    }

  }

  h1 {
    color: $secondary;
    font-size: 22px;
    line-height: normal;
    padding-top: 8px;
  }

  /*stacking fieldsets above each other*/
  position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

/*inputs*/
/*#msform input, #msform textarea {
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  color: #2C3E50;
  font-size: 13px;
}*/
/*buttons*/
#msform .action-button {
  width: 100px;
  padding: 10px;
  font-weight: bold;
  color: white;
  border: 0 none;
  cursor: pointer;
}

#msform .action-button:hover, #msform .action-button:focus {
  //box-shadow: 0 0 0 2px white, 0 0 0 3px #27AE60;
}

/*headings*/
.fs-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #2C3E50;
  margin-bottom: 10px;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 33.33%;
  float: left;
  position: relative;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 3px;
  margin: 0 auto 5px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: $white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before, #progressbar li.active:after {
  background: $primary;
  color: $white;
}


@include media-breakpoint-up(sm) {
  .custom-modal-centered {
    max-width: 500px;
  }

  .add-pet-modal-wrap {
    .custom-modal-centered {
      max-width: 100%;
    }

  }

  .modal-form-body {

    padding: 1rem 2rem;
  }

  .modal-modify {
    .info-gray-part {
      .gray-info-wrap {
        max-width: 390px;
        margin: 0 auto;

        .view-left-side {
          .details {
            span {
              max-width: 75px;
            }
          }
        }

        .view-right-side {
          .details {
            span {
              max-width: 75px;
            }
          }
        }

      }
    }
  }
}

@include media-breakpoint-up(md) {

  #msform {
    min-width: 800px;
    height: calc(100vh - 80px);
  }

  .modal {
    &.custom-modal {
      top: 100px;
      padding-top: 0;
    }
  }

  .add-pet-modal-wrap {
    .custom-modal-content {
      align-items: center;
    }
  }

  .field-wrap {
    height: 100%;

    .modal-form {
      min-width: 500px;
      padding: 0 10px;
    }
  }

  .choose-pet-wrap {
    figure {
      background: $linearGradient;
      width: 180px;
      height: 180px;
      border-radius: 8px;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {

  #msform fieldset {
    h1 {
      font-size: 38px;
      line-height: normal;
    }
  }

  .modal {
    &.custom-modal {
      top: 140px;
      left: 310px;
    }

    &.add-pet-modal-wrap {
      top: 80px;
      left: 250px;
    }
  }

  .choose-pet-wrap {
    figure {
      background: $linearGradient;
      width: 250px;
      height: 250px;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .base {
    padding: 20px;
  }

  .custom-modal {
    .close {
      top: 0;
      right: 14px;
      font-size: 22px;
      position: absolute;
      padding: 0 !important;
    }

  }

  .add-pet-modal-wrap {
    .custom-modal-centered {
      max-width: 100% !important;
    }
  }

}

@include media-breakpoint-up(xxl) {
  .modal {
    &.add-pet-modal-wrap {
      left: 310px;
    }
  }
}