//pets-page

.your-pet {
  font-size: 28px;
}

.total-pet {
  font-size: 28px;
}

.add-your-pet {
  background-color: $gray-ef;
  min-height: 348px;

  .add-your-pet {
    button{
      font-size: 100px;
      color: $gray-b5;
    }
  }
}

//new design css
.owners-info-wrap {
  .owner-info {
    flex: 0 0 280px;
    font-size: 16px;
  }
}

.add-charge-modal {
  .custom-control-label {
    font-size: 20px;

    &:before {
      box-shadow: 0 0 3px rgba($black, 0.7);
    }
  }
}

.table-viewlist {
  .file-result {
    font-size: 20px;
    color: $gray;
  }

  .export-link {
    display: inline-block;
    font-size: 20px;
    color: $gray;
    text-decoration: underline;
  }
}

.export-modal-body {
  padding: 30px 10px 15px;

  h5 {
    font-weight: bold;
    font-size: 16px;
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 0;
    line-height: 1;
  }

  .dt-button{
    background-color: $gray-e6;
    font-size: 18px;
    color: $gray;
    border-radius: 10px;
    display: inline-block;
    line-height: 1;
    padding: 15px;
    width: 141px;
    height: 46px;
    margin: 0 5px;
    text-decoration: none;
  }
}

@include media-breakpoint-up(md) {
  .your-pet {
    font-size: 38px;
  }

  .total-pet {
    font-size: 38px;
  }

  .owners-info-wrap {
    .owner-info {
      flex: 0 0 300px;
      font-size: 20px;
    }
  }
}

@include media-breakpoint-up(xl) {

  .your-pet {
    font-size: 44px;
  }

  .total-pet {
    font-size: 44px;
  }

  .owners-info-wrap {
    .owner-info {
      font-size: 25px;
    }
  }
}
