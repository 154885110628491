/* Auth layout */

.auth-wrap {

}

.auth-main {
  padding-top: 20px;
}

.auth-sidebar {
  position: relative;
  z-index: 2;
}

.auth-content {
  padding-bottom: 62px;
}


@media (min-width: 375px){

  .auth-content {
    padding-bottom: 93px;
  }

}


@include media-breakpoint-up(lg) {

  .auth-main {
    height: calc(100vh - 248px);
    overflow-y: auto;
    @include vpad(50);
  }

  .auth-wrap {
    @include make-row;
    @include hmar(0);
    height: 100vh;
    overflow: hidden;
  }

  .auth-sidebar {
    @include make-col-ready;
    @include make-col(1,3);
    @include hpad(0);
  }

  .auth-content {
    @include make-col-ready;
    @include make-col(2,3);
    @include vpad(124);
    @include hpad(0);
  }

}