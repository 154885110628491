//dashboard header

.navbar {
  padding: 15px 10px;
  background: $white;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
}

.custom-nav {
  ul{
    li{
      a{
       text-decoration: none;
      }
    }
  }
}

.header-add-btn{
  i{
  display: inline-block;
  vertical-align: middle;
  margin-top: 0 !important;
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}


.dash-logo{
  width: 90px;
}

.user-profile{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }
}

.profile-dropdown{

}

.header-language {
  a {
    //color: $gray;
    padding: 0 5px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    cursor: pointer;
    &:first-child{
      border-right: 2px solid $gray;
    }

    &.active{
      color: $primary;
    }
  }
}

.notification {
  position: relative;
  font-size: 30px;
  i{
    position: absolute;
    top: 0;
    font-size: 14px;
    right: -3px;
    background-color: $white;
    color: $warning;
    border-radius: 50%;
  }
}

@include media-breakpoint-up(lg){


  .user-profile{
    width: 55px;
    height: 55px;
    margin-top: 10px;
  }

  .dash-logo{
    width: 130px;
  }

  .custom-nav {
    min-height: 124px;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  .header-add-btn{

    i{
      margin-top: -3px !important;
    }
  }
}

@media (min-width: 1200px) {
  .custom-nav {
    min-height: 90px;
  }
}

@include media-breakpoint-up(xxl){

  .custom-nav {
    min-height: 124px;
  }

}
