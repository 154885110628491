/* Header */

header {
  position: relative;
  background-color: $white;
  height: 62px;
  z-index: 1;
}

@include media-breakpoint-up(md) {

  header {
    height: 93px;
  }

}


@include media-breakpoint-up(lg) {

  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 124px;
    margin-left: 33%;
  }

}