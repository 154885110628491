//lost-pets content

.lost-alert{
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 3px rgba($black, 0.16);
  line-height: 1;
}

.search-left{
  color: $secondary;
  .search-icon {
    font-size: 25px;
    color: $secondary;
  }
}

.lost-search{
  max-width: 300px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  input{
    background-color: $gray-e;
    padding-left: 25px;
    border-radius: 20px;
    padding-right: 70px;
    &:focus {
      background-color: $gray-e;
    }
  }

  button{
    position: absolute;
    padding: 10px 10px;
    line-height: 1;
    top: 0;
    right: 0;
  }
}

.lost-title-info{
  padding-top: 40px;
  padding-bottom: 10px;
  h2{
    color: $secondary;
    font-size: 30px;
  }

  p{
    padding-top: 10px;
    font-size: 18px;
    margin-bottom: 0;
  }
}

.lost-pet-owner{
  background-color: $white-f9;
  text-align: center;
  padding: 12px 5px;
}

.return-home{
  h2{
    color: $secondary;
    font-size: 30px;
  }
}

.lost-contact{
  margin-bottom: 10px;
  a{
    text-decoration: none;
    color: $body-text;
  }
}

.btn-recover {
  span{
    border-radius: 0;
    background-image: none;
    background-color: $green;
    padding: 8px 30px;
    line-height: 1;
  }
}

@include media-breakpoint-up(md){

  .lost-title-info{
    h2{
      color: $secondary;
      font-size: 44px;
    }
  }

  .return-home{
    h2{
      font-size: 44px;
    }
  }

  .lost-search{
      max-width: 500px;
      margin: 0;
    input {
      padding-right: 135px;
    }
    button{
      padding: 10px 40px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .lost-search{
    max-width: 650px;
  }
}